import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {Permissions} from "@app/core/constants";

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private _http = inject(HttpClient);
  private _permissions$ = new BehaviorSubject<string[]>([]);

  constructor() {
    this.loadUserPermissions().subscribe();
  }

  loadUserPermissions(): Observable<string[]> {
    return of({
      permissions: [
        Permissions.UserManagement,
        Permissions.ExpertManagement,
        Permissions.VoucherManagement,
        Permissions.Exchange,
        Permissions.BankAccounts,
        Permissions.Banks,
        Permissions.Kyc,
      ].flat()
    }).pipe(
      map(response => response.permissions),
      tap(response => {
        this._permissions$.next(response);
      }),
      catchError(() => {
        this._permissions$.next([]);
        return [];
      })
    )
    return this._http.get<{ permissions: string[] }>('/api/permissions').pipe(
      tap(response => {
        this._permissions$.next(response.permissions);
      }),
      map(response => response.permissions),
      catchError(() => {
        this._permissions$.next([]);
        return [];
      })
    );
  }

  userHasPermission(permissionKey: string | string[] | undefined): Observable<boolean> {
    if (permissionKey === undefined) return of(true);
    return this.checkPermissions(permissionKey);
  }

  private checkPermissions(permissionKey: string | string[]): Observable<boolean> {
    return this._permissions$.pipe(
      map(permissions => {
        if (Array.isArray(permissionKey)) {
          return permissionKey.every(key => permissions.includes(key));
        }
        return permissions.includes(permissionKey);
      })
    );
  }
}
